
@media(max-width:1199px)   {
    .distribution-page .search-result-box .text-box {
        padding: 41px 20px 50px 20px;
    }
    .contact-us-page .search-result-box .text-box{
        padding: 46px 20px 70px;
    }
    .contact-us-page .search-result-box .text-box h3{
        font-size: 16px;
    }
    .collection-grades-box .image-holder{
        background-color: #ffffff;
        border: 1px solid #cacaca;
        padding: 20px;
    }
    .collection-grades-box .image-holder img{
        display: block;
        margin: auto;
        width: 100%;
        max-width: 150px;
        min-height: 150px;
        max-height: 150px;
    }
    .gallery-inner-sec{
        padding: 60px 0 120px;
        background-color: #fafafa;
    }
    .gallery-inner-sec .collection-breadcrumb {
        margin-bottom: 67px;
    }
    .gallery-inner-sec .collection-breadcrumb h3{
        color: #850037;
        font-size: 40px;
        font-family: 'FaunaOne';
    }
    .gallery-inner-sec .mobile-version{
        display: none;
    }
    .gallery-inner-box .image-holder{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        margin-bottom: 30px;
        min-width: 250px;
        max-width: 250px;
        min-height: 250px;
        max-height: 250px;
        overflow: hidden;
    }
    .gallery-inner-box .small-left{
        float: right;
        margin-bottom: 10px;
    }
    .gallery-inner-box .left-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        float: right;
        margin-bottom: 30px;
        min-width: 570px;
        max-width: 570px;
        min-height: 510px;
        max-height: 510px;
    }
    .gallery-inner-box .small-right{
        float: left;
        margin-left: -20px;
        margin-bottom: 10px;
    }
    .gallery-inner-box .right-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        margin-left: -20px;
        margin-bottom: 30px;
        min-width: 570px;
        max-width: 570px;
        min-height: 510px;
        max-height: 510px;
    }
    .gallery-inner-box .image-holder img{
        object-fit: cover;
        width: 228px;
        height: 228px;
    }
    .gallery-inner-box .left-img img{
        object-fit: cover;
        width: 600px;
        height: 490px;
    }
    .gallery-inner-box .right-img img{
        object-fit: cover;
        width: 600px;
        height: 490px;
    }

}
@media(max-width:991px)    {
    /* The Overlay (background) */
.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 0%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: #006148; /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }

  /* Position the content inside the overlay */
  .overlay-content {
    text-align: left; /* Centered text/links */
    margin-top: 0px; /* 30px top margin to avoid conflict with the close button on smaller screens */
  }

  /* The navigation links inside the overlay */
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: white;
    display: block; /* Display block instead of inline */
  }

  .overlay li {
      margin-bottom: 20px;
  }

  /* Position the close button (top right corner) */
  .overlay .closebtn {
    font-size: 50px;
    font-weight: lighter;
  }

  .collapse:not(.show) {
    display: block;
  }

  .dropdown-menu.show {
      background: #006148;
      color: white;
      border: none;
      transition: all 2s;
  }
  
  .dropdown-menu.show .dropdown-item:active {
      background: #006148;
      color: white;
      border: none;
  }  
  .dropdown-menu.show .dropdown-item:hover {
      background: #006148;
      color: white;
      border: none;
  }  
  .dropdown-toggle::after {
      border: none !important;
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f107" !important;
      vertical-align: 0;
  }  
  .dropdown-item:focus {
      background-color: #006148;
  }  
  #menu-dropdown {
      position: unset;
  }  
  #menu-dropdown a{
      color: white;
      margin-left: -20px;
      font-weight: 500;
  }  
  #menu-dropdown:after, #menu-dropdown:before {
      display: none;
  }

/* SEARCH MODAL */

.modal-custom {
    width: 100%;
    padding: 0;
    margin: 0;
}

.modal-custom div {
    height: 100vh;
    width: 100vw;
}

.modal-content {
    border-radius: 0;
}

.fade.modal {
    padding: 0 !important;
}

.fade.modal.show {
    padding: 0 !important;
}

.close-search {
    font-size: 60px;
    font-weight: lighter;
    margin-left: 20px;
    cursor: pointer;
}

.search-input-container {
    font-size: 25px;
    margin-left: 20px;
    border: none;
}

#search-input {
    width: 100%;
    font-family: 'FaunaOne';
    border: none;
}

::-webkit-input-placeholder {
    font-style: unset;
 }
 :-moz-placeholder {
    font-style: unset;
 }
 ::-moz-placeholder {
    font-style: unset;
 }
 :-ms-input-placeholder {
    font-style: unset;
 }

#search-button {
    position: absolute;
    width:43px;
    color: #717171;
    border-color: #dddddd;
    background: #fafafa;
    letter-spacing: 4px;
    bottom: 175px;
    left: 0;
    right: 0;
    margin: auto;
}

    header {
        padding: 10px 0;
    }
    .navbar-toggler{
        border: none;
        border-radius: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navbar-toggler-icon{
        background-color: #fff;
        width: 43px;
        height: 2px;
        display: block;
        margin-bottom: 7px;
    }
    .navbar-toggler-icon:nth-child(2){
        width: 34px;
    }
    .navbar-toggler-icon:last-child{
        margin-bottom: 0;
    }
    .hardwood-option-box {
        padding: 15px;
    }
    .hardwood-option-box .text-box h3{
        font-size: 13px;
        height: 56px;
        overflow: hidden;
    }
    .hardwood-option-box .text-box a,
    .hardwood-option-detail .text-box a{
        letter-spacing: 0px;
    }
    .hardwood-list-inner .text-inner h3 {
        font-size: 30px;
        line-height: 36px;
        padding-bottom: 10px;
    }
    .hardwood-list-inner .text-inner span {
        letter-spacing: 1px;
        margin: 10px 0 9px;
        font-size: 12px;
    }
    .hardwood-list-inner .text-inner p {
        font-size: 14px;
        line-height: 24px;
    }
    .hardwood-list-inner .text-inner {
        min-height: 446px;
        margin-left: -30px;
        padding-left: 30px;
    }
    .hardwood-list-inner-right .text-inner {
        margin-right: -30px;
        margin-left: auto;
        padding-right: 30px;
    }
    .dstributor-inner {
        padding: 140px 60px;
        margin-top: -75px;
    }
    .family-owned-inner h3 {
        font-size: 38px;
    }
    .search-result-detail {
        align-items: flex-start;
        flex-direction: column;
    }
    .search-result-detail ul {
        width: 100%;
        margin-top: 20px;
    }
    .search-result-box .text-box {
        padding: 20px;
    }
    .search-result-box .text-box p {
        font-size: 10px;
        letter-spacing: 2px;
    }
    .search-result-box .text-box h3 {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
    }
    .collection-grades-box .image-holder{
        background-color: #ffffff;
        border: 1px solid #cacaca;
        padding: 20px;
    }
    .collection-grades-box .image-holder img{
        display: block;
        margin: auto;
        width: 100%;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
    }
    .collection-grades-box h3 {
        font-size: 12px;
    }
    .collection-detail-sec .text-plank {
        padding: 20px 0 0 0;
    }
    .collection-detail-sec .text-plank h3 {
        font-size: 32px;
        line-height: 40px;
        margin: 10px 0 15px;
        padding-bottom: 15px;
    }
    .collection-detail-sec .text-plank p {
        line-height: 28px;
        font-size: 14px;
    }
    .carousel-thumbs {
        width: 250px;
        height: 100%;
        min-height: 40px;
        max-height: 40px;
    }
    .colonial-grades-sec .text-box {
        padding: 30px 30px 20px;
        margin-left: -30px;
        margin-top: 25px;
    }
    .colonial-grades-sec .text-box h3 {
        line-height: 36px;
        font-size: 32px;
        padding-bottom: 15px;
    }
    .colonial-grades-sec .text-box p {
        font-size: 14px;
        line-height: 26px;
        padding-top: 10px;
    }
    .colonial-grades-box-second {
        margin-top: 100px;
    }

    /* LIGHTBOX */
    .ril__navButtonPrev {
        width: 175px !important;
    }

    .ril__navButtonNext {
        width: 175px !important;
    }

    .lightbox-caption {
        width: 100%;
        height: auto;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        text-align: center;
        background-color: white;
    }
    .gallery-inner-sec{
        padding: 60px 0 120px;
        background-color: #fafafa;
    }
    .gallery-inner-sec .collection-breadcrumb {
        margin-bottom: 67px;
    }
    .gallery-inner-sec .collection-breadcrumb h3{
        color: #850037;
        font-size: 40px;
        font-family: 'FaunaOne';
    }
    .gallery-inner-sec .mobile-version{
        display: none;
    }
    .gallery-inner-box .image-holder{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        margin-bottom: 30px;
        min-width: 200px;
        max-width: 200px;
        min-height: 200px;
        max-height: 200px;
        overflow: hidden;
    }
    .gallery-inner-box .small-left{
        float: right;
        margin-bottom: 10px;
    }
    .gallery-inner-box .left-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 410px;
        max-height: 410px;
    }
    .gallery-inner-box .small-right{
        float: left;
        margin-left: -20px;
        margin-bottom: 10px;
    }
    .gallery-inner-box .right-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 410px;
        max-height: 410px;
    }
    .gallery-inner-box .image-holder img{
        object-fit: cover;
        width: 178px;
        height: 178px;
    }
    .gallery-inner-box .left-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
    .gallery-inner-box .right-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }

    #canada-dist-home {
        top: 15px;
        right: 60px;
        color: #bec4c2;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .05em;
    }
}
@media(max-width:767px)    {
    .ril__image img {
        min-width: 100px !important;
    }
    .lightbox-caption {
        width: 100%;
        height: auto;
        min-height: 144.5px;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        text-align: center;
        background-color: white;
        padding: 10px;
    }
    
    .caption-title {
        font-family: 'FaunaOne';
        font-size: 2em;
        margin-bottom: 30px;
    }
    
    .caption-desc {
        margin-top: -10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
    }
    .container{
        max-width: 100%;
    }
    .hero-text h1 br{
        display: none;
    }
    .hero-text {
        padding: 196px 10px;
        text-align: center;
    }
    .hero-text h1 {
        font-size: 68px;
        text-align: center;
    }
    .hero-button {
        text-align: center;
    }
    .hero-text h1 sup {
        font-size: 18px;
    }
    .hardwood-option-sec {
        padding: 73px 0;
    }
    .title {
        padding-bottom: 20px;
    }
    .hardwood-option-inner {
        padding-top: 40px;
    }
    .hardwood-option-box {
        padding: 10px;
    }
    .hardwood-option-inner .hardwood-block:nth-child(odd) {
        padding-right: 10px !important;
    }
    .hardwood-option-inner .hardwood-block:nth-child(even) {
        padding-left: 10px !important;
    }
    .hardwood-option-detail {
        padding: 15px;
        margin-top: 30px;
    }
    .hardwood-option-detail .image-holder img {
        width: 100%;
        min-height: 161px;
        object-fit: cover;
    }
    .hardwood-option-detail .text-box {
        padding-top: 25px;
        text-align: center !important;
    }
    .hardwood-option-detail .text-box p {
        display: none
    }
    .hardwood-option-box .text-box p{
        display: none;
    }
    .hardwood-option-box .text-left {
        text-align: center !important;
    }
    .hardwood-option-box .text-right {
        text-align: center !important;
    }
    .hardwood-option-box .justify-content-start{
        justify-content: center !important;
    }
    .hardwood-option-box .justify-content-end{
        justify-content: center !important;
    }
    .hardwood-list-inner .text-inner {
        height: auto;
        padding: 43px 26px;
        width: 93%;
        margin: -130px auto 0;
    }
    .hardwood-option-box .text-box {
        padding-top: 19px;
        font-size: 1px;
    }
    .hardwood-list-inner .text-box {
        margin: 0px;
    }
    .hardwood-list-inner-right .text-box {
        margin: 0px;
    }
    .hardwood-option-box .text-box h3 {
        font-size: 14px;
        max-width: 200px;
        margin: 0 auto;
        line-height: 22px;
        min-height: 100px;
    }
    .hardwood-option-detail .text-box h3 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
        min-height: 100px;
    }
    .hardwood-list-inner .text-inner h3 {
        font-size: 40px;
        line-height: 47px;
        padding-bottom: 32px;
    }
    .hardwood-list-inner .text-inner h3::after{
        width: 282px;
    }
    .hardwood-list-inner .text-inner span {
        font-size: 18px;
        padding: 22px 0 12px;
    }
    .hardwood-list-inner .text-inner p {
        font-size: 24px;
        line-height: 40px;
    }
    .hardwood-option-detail {
        margin-top: 38px;
    }
    .hardwood-option-box {
        margin: 38px 0;
    }
    .hardwood-list-inner {
        padding-bottom: 88px;
    }
    .hardwood-list-inner-right .text-inner {
        text-align: left;
    }
    .hardwood-list-inner-right .text-inner h3::after {
        left: 0;
        right: auto;
    }
    .distributor-sec {
        padding: 0 0 60px 0;
    }
    .dstributor-inner {
        padding: 140px 32px 100px;
        margin-top: 0;
        margin: -60px -15px;
    }
    .dstributor-inner span {
        letter-spacing: 2px;
        max-width: 290px;
        font-size: 12px;
        padding-bottom: 35px;
    }
    .dstributor-inner h3 {
        font-size: 55px;
        padding: 47px 0 20px 0;
    }
    .dstributor-inner p {
        line-height: 40px;
        font-size: 24px;
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin: 0 auto 22px;
    }
    .dstributor-inner form {
        max-width: 100%;
        flex-direction: column;
        padding: 0 33px;
    }
    .dstributor-inner .form-control {
        padding-left: 0;
        width: 100%;
        margin-right: 0;
        height: 53px;
        margin-bottom: 30px;
        text-align: center;
    }
    .dstributor-inner form a{
        max-width: 100%;
        height: 80px;
        font-size: 18px;
    }
    #canada-dist {
        text-align: left;
        padding-left: 50px;
        padding-right: 50px;
    }

    #inner-canada-dist {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    #canada-dist-home {
        top: 15px;
        right: 60px;
        padding-left: 33px;
        padding-right: 33px;
        color: #bec4c2;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .05em;
    }

    #canada-dist-home h6 {
        margin-top: 30px;;
        color: white;
        text-align: left;
        margin-bottom: 15px;
    }
    
    #canada-dist-home p {
        color: #bec4c2;
        line-height: unset;
        font-size: 14px;
        text-align: left;
        margin-bottom: 0px;
    }
    
    #canada-dist-home ul {
        text-align: left;
        margin-bottom: 10px;
    }
    
    #canada-dist-home a {
        float: left;
        color: #bec4c2;
        border-bottom: 1px solid #bec4c2;
    }
    .hardwood-list {
        padding: 40px 0 0 0;
    }
    .family-owned-sec {
        padding: 121px 0;
    }
    .family-owned-inner h3 {
        font-size: 46px;
        padding: 51px 0 9px;
    }
    .family-owned-inner p {
        line-height: 40px;
        font-size: 24px;
        max-width: 100%;
    }
    .footer-inenr .text-box p span {
        display: block;
        padding: 5px 0 0 0;
    }
    .footer-inenr .text-box p {
        letter-spacing: 3px;
        max-width: 310px;
        margin: 0 auto 12px;
    }
    .copyright p br{
        display: block;
    }
    .copyright p span {
        display: block;
        padding-left: 0;
    }
    .copyright p {
        letter-spacing: 3px;
        line-height: 20px;
    }
    .footer-logo ul li {
        margin: 0 18px;
    }
    .footer-logo {
        margin: 65px 0 0 0;
    }
    .footer-logo ul{
        max-width: 440px;
    }
    .footer-sec {
        padding: 140px 0 75px;
    }
    .search-result-box .text-box p {
        font-size: 15px;
        max-width: 100%;
        margin-bottom: 12px;
    }
    .search-result-box .text-box h3 {
        font-size: 22px;
    }
    .search-result-box .text-box {
        padding: 45px 10px 75px 42px;
    }
    .filter-options {
        display: hidden;
        margin-top: -8px;
    }
    .collection-detail-slide .carousel-inner{
        padding: 15px;
    }
    .carousel-thumbs {
        width: 60px;
        height: 100%;
        min-height: 40px;
        max-height: 40px;
    }
    .collection-detail-sec {
        padding: 57px 0;
    }
    .collection-breadcrumb {
        margin-bottom: 40px;
    }
    .collection-detail-sec .text-plank span{
        font-size: 12px;
    }
    .collection-detail-sec .text-plank h3 {
        font-size: 40px;
        line-height: 47px;
        margin: 18px 0 26px;
        padding-bottom: 32px;
    }
    .collection-detail-sec .text-plank p {
        line-height: 50px;
        font-size: 24px;
    }
    .collection-detail-sec .text-plank h4{
        margin-bottom: 15px;
    }
    .collection-detail-sec .text-plank {
        padding: 60px 0 0 0;
    }
    .collection-detail-slide .carousel-indicators {
        padding: 0;
        margin: 20px -10px 0;
    }
    .collection-grades {
        margin: 53px 0 0 0;
        padding-top: 63px;
    }
    .heading p {
        line-height: 40px;
        font-size: 24px;
    }
    .collection-grades-box .image-holder{
        background-color: #ffffff;
        border: 1px solid #cacaca;
        padding: 20px;
        width: 200px;
        margin: auto;
    }
    .collection-grades-box .image-holder img{
        display: block;
        margin: auto;
        width: 100%;
        max-width: 150px;
        min-height: 150px;
        max-height: 150px;
    }
    .collection-grades-box h3 {
        font-size: 14px;
    }
    .collection-grades-box {
        margin: 22px 0;
    }
    .collection-grades-inner {
        padding: 70px 0 0 0;
    }
    .heading h3 span{
        display: none;
    }
    .collection-packaging-left .text-box p {
        font-size: 20px;
    }
    .collection-packaging-left .text-box ul li {
        font-size: 20px;
    }
    .collection-packaging-inner {
        padding: 92px 0 0 0;
    }
    .collection-packaging-right .text-box ul li,
    .collection-packaging-right .text-box p{
        font-size: 20px;
    }
    .colonial-grades-sec .text-box {
        padding: 43px 22px 20px;
        width: 92%;
        margin: -130px auto 0;
    }
    .colonial-grades-sec .text-box h3 {
        line-height: 47px;
        font-size: 40px;
        padding-bottom: 33px;
    }
    .colonial-grades-sec .text-box p {
        font-size: 24px;
        line-height: 40px;
        padding-top: 20px;
    }
    .colonial-grades-box-second .text-box {
        text-align: left;
    }
    .colonial-grades-box-second .text-box h3::after {
        left: 0;
        right: auto;
    }
    .slideshow-sec .text-box p {
        line-height: 40px;
        font-size: 24px;
        margin: 50px auto 0;
    }
    .slideshow-sec .text-box {
        padding-bottom: 69px;
        padding-top: 70px;
    }
    .slideshow-sec .text-box span{
        padding-left: 50px;
        padding-right: 50px;
    }
    .slideshow-sec .text-box span img{
        max-width: 227px;
        width: 100%;
    }
    .gallery-inner-sec .mobile-version{
        display: block;
        margin: auto;
        max-width: 662px;
    }
    .gallery-inner-sec .mobile-version .row > div{
        padding:0
    }
    .gallery-inner-sec .dasktop-version{
        display: none;
    }
    .gallery-inner-sec {
        padding: 60px 0 0;
    }
    .gallery-inner-sec .collection-breadcrumb {
        margin-bottom: 47px;
    }
    .gallery-inner-sec{
        padding: 60px 0 120px;
        background-color: #fafafa;
    }
    .gallery-inner-box .image-holder{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 10px;
        margin-bottom: 30px;
        height: auto;
        overflow: hidden;
        min-width: 100px;
        max-width: 300px;
        min-height: 100px;
        max-height: 300px;
    }
    .gallery-inner-box .small-left{
        float: right;
        margin-right: 5px;
        margin-bottom: 10px;
    }
    .gallery-inner-box .left-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .small-right{
        float: left;
        margin-left: 5px;
        margin-bottom: 10px;
    }
    .gallery-inner-box .right-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .image-holder img{
        object-fit: cover;
        width: 278px;
        height: 278px;
    }
    .gallery-inner-box .left-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
    .gallery-inner-box .right-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
    .distribution-banner {
        padding: 190px 0 195px;
        text-align: center;
    }
    .distribution-banner h2 br{
        display: none;
    }
    .distribution-banner .text-box h2 {
        font-size: 60px;
        max-width: 340px;
        margin: 0 auto 56px;
    }
    .distribution-banner .text-box h2 span {
        font-size: 16px;
        top: 60px;
    }
    .distribution-banner .text-box form{
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
    }
    .distribution-banner .text-box form .form-control{
        width: 100%;
        padding-left: 0;
        text-align: center;
    }
    .distribution-banner .text-box form a{
        max-width: 100%;
        margin-top: 30px;
    }
    .distribution-page .search-result-detail .title{
        padding-bottom: 33px;
    }
    .distribution-page .search-result-detail .title span {
        font-size: 12px;
        letter-spacing: 2px;
        max-width: 280px;
        line-height: 18px;
    }
    .distribution-page .search-result-box .text-box ul li {
        font-size: 20px;
        margin-bottom: 6px;
    }
    .distribution-page .search-result-box .text-box span{
        margin-bottom: 13px;
    }
    .distribution-page .search-result-box .text-box ul {
        margin: 42px 0 46px;
    }
    .distribution-page .search-result-box .text-box {
        padding: 46px 20px 45px 44px;
    }
    .distribution-page .search-result-sec {
        padding: 65px 0 70px;
    }
    .template-page .distribution-banner .text-box h2 {
        font-size: 68px;
        max-width: 100%;
        margin: 0;
        text-align: center;
    }
    .template-page .distribution-banner {
        padding: 64px 0 68px;
    }
    .template-page .distribution-banner .text-box h2 span {
        top: -12px;
    }
    .tempate-qualtiy .text-box h3{
        line-height: 46px;
        padding-bottom: 35px;
    }
    .tempate-qualtiy .text-box span{
        font-size: 12px;
    }
    .tempate-qualtiy .text-box p {
        font-size: 24px;
        line-height: 40px;
    }
    .tempate-qualtiy {
        padding: 102px 0 70px;
    }
    .template-letter-sec .text-box {
        width: 98%;
        margin: 30px auto 0;
        padding: 55px 20px 75px;
    }
    .template-letter-sec .text-box span {
        font-size: 12px;
        padding: 0 30px 36px;
    }
    .template-letter-sec .text-box p {
        font-size: 24px;
        line-height: 40px;
        max-width: 420px;
        margin: 50px auto 35px;
    }
    .template-letter-sec .text-box h4{
        font-size: 20px;
        margin-bottom: 4px;
    }
    .template-letter-sec .text-box strong {
        font-size: 16px;
    }
    .template-title h3::after{
        display: none;
    }
    .template-title p {
        font-size: 24px;
        line-height: 40px;
        text-align: center;
    }
    .template-millions-inner ul li {
        width: 50%;
        padding: 40px 0;
    }
    .template-millions-inner.dasktop-version{
        display: none;
    }
    .template-millions-inner.mobile-version{
        display: block;
        padding: 40px 0 0;
        max-width: 400px;
        margin: auto;
    }
    .template-millions-sec {
        padding: 143px 0 60px;
    }
    .template-page .distribution-banner .text-box.mobile-version{
        display: block;
    }
    .template-page .distribution-banner .text-box.dasktop-version{
        display: none;
    }
    .contact-banner .text-box {
        text-align: center;
    }
    .contact-us-page .text-box {
        text-align: center;
    }
    .contact-us-page .search-result-inner .title h2 br{
        display: none;
    }
    .contact-us-page .search-result-inner .title span{
        font-size: 12px;
    }
    .contact-us-page .search-result-detail p {
        font-size: 24px;
        line-height: 40px;
    }
    .contact-us-page .search-result-detail .text-box {
        flex-direction: column;
    }
    .contact-us-page .search-result-detail .text-box ul{
        margin-top: 0;
    }
    .contact-us-page .search-result-detail .text-box ul li {
        font-size: 24px;
        color: #555555;
    }
    .contact-us-page .search-result-detail {
        margin-bottom: 80px;
    }
    .contact-us-detail-inner .form-group a {
        margin: 0 auto;
    }
    .contact-us-detail-inner{
        display: block;
    }
    .contact-us-getting {
        display: block;
    }
    .contact-us-page .search-result-box .text-box {
        text-align: left;
        min-height: 190px;
        max-height: 190px;
    }
}
@media(max-width: 660px) {
    .gallery-inner-sec .mobile-version{
        display: block;
        margin: auto;
    }
    .gallery-inner-sec .dasktop-version{
        display: none;
    }
    .gallery-inner-sec {
        padding: 60px 0 0;
    }
    .gallery-inner-sec .collection-breadcrumb {
        margin-bottom: 47px;
    }
    .gallery-inner-sec{
        padding: 60px 0 120px;
        background-color: #fafafa;
    }
    .gallery-inner-box .image-holder{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 5px;
        margin-bottom: 30px;
        height: auto;
        overflow: hidden;
        min-width: 100px;
        max-width: 200px;
        min-height: 100px;
        max-height: 200px;
    }
    .gallery-inner-box .left-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .right-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .image-holder img{
        object-fit: cover;
        width: 178px;
        height: 178px;
    }
    .gallery-inner-box .left-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
    .gallery-inner-box .right-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
}
@media(max-width:575px)    {
    .filter-options a {
        font-size: 14px;
    }
    .mobile-manu{
        padding: 30px;
    }
    .mobile-manu ul li {
        margin-bottom: 30px;
    }
    .search-filter{
        padding: 20px;
    }
    .search-filter .cross-icon {
        padding: 0 0 20px 10px;
    }
    .search-filter-box h3 {
        font-size: 14px;
        letter-spacing: 1px;
        padding-left: 10px;
        padding-bottom: 10px;
    }
    .search-filter-box ul li a {
        font-size: 14px;
        letter-spacing: 1px;
        padding: 15px 10px;
    }
    .search-filter-box {
        margin-bottom: 30px;
    }
    .search-filter-box:last-child{
        margin-bottom: 0;
    }

}
@media(max-width:479px)   {
    .header-home {
        height: 100vh;
    }
    .hero-button {
        margin-top: 150px;
        text-align: center;
    }
    .hero-text h1 {
        margin-top: 100px;
        font-size: 40px;
        text-align: center;
        margin-bottom: 30px;
    }
    .hero-text {
        padding: 70px 10px;
    }
    .carousel-btn {
        bottom: 20px;
    }
    .btn{
        height: 48px;
        min-width: 210px;
    }
    .hardwood-option-sec {
        padding: 50px 0 0;
    }
    .title span {
        letter-spacing: 2px;
        margin-bottom: 7px;
    }
    .title h2 {
        font-size: 28px;
        font-family: 'FaunaOne';
    }
    .hardwood-option-inner .col-6{
        flex: 0 0 100%;
        max-width: 50%;
    }
    .hardwood-option-box .text-box a {
        font-size: 10px;
    }
    .hardwood-option-box {
        margin: 0 0 70px 0;
    }
    .hardwood-option-inner {
        padding-top: 30px;
    }
    .hardwood-option-detail {
        margin-top: 0;
        margin-bottom: 70px;
    }
    .hardwood-list {
        padding: 20px 0;
    }
    .hardwood-list-inner .text-inner {
        padding: 30px 15px;
    }
    .hardwood-list-inner .text-inner h3 {
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 11px;
    }
    .hardwood-list-inner .text-inner h3::after {
        width: 100%;
    }
    .hardwood-list-inner .text-inner span {
        font-size: 14px;
        padding: 10px 0 12px;
        letter-spacing: 0;
        line-height: 20px;
    }
    .hardwood-list-inner .text-inner p {
        font-size: 16px;
        line-height: 30px;
    }
    .hardwood-list-inner {
        padding-bottom: 0;
    }
    .hardwood-list-inner {
        padding-bottom: 88px;
    }
    .dstributor-inner {
        padding: 120px 15px 50px;
        margin: -60px -15px;
    }
    .dstributor-inner span {
        letter-spacing: 1px;
        max-width: 250px;
        font-size: 12px;
        padding-bottom: 15px;
    }
    .dstributor-inner h3 {
        font-size: 30px;
        padding: 30px 0 20px 0;
    }
    .dstributor-inner p {
        line-height: 30px;
        font-size: 16px;
        text-align: center;
        margin: 0 auto 10px;
    }
    .dstributor-inner form a {
        height: 60px;
        font-size: 14px;
    }
    .family-owned-sec {
        padding: 50px 0;
    }
    .family-owned-inner span {
        letter-spacing: 1px;
        padding-bottom: 15px;
    }
    .family-owned-inner h3 {
        font-size: 28px;
        padding: 20px 0;
        line-height: 40px;
    }
    .family-owned-inner p {
        line-height: 30px;
        font-size: 16px;
    }
    .slideshow-sec .owl-theme .owl-nav [class*=owl-] img{
        max-width: 50px;
    }
    .slideshow-sec .owl-theme .owl-nav [class*=owl-] {
        margin: 0 10px;
    }
    .slideshow-sec .owl-theme .owl-nav {
        bottom: 10px;
    }
    .footer-sec {
        padding: 50px 0;
    }
    .footer-logo ul li {
        margin: 0 10px;
    }
    .footer-logo {
        margin: 30px 0 0 0;
    }
    .search-result-sec {
        padding: 50px 0;
    }
    .filter-options {
        margin-top: 20px;
    }
    .search-result-detail {
        margin-bottom: 30px;
    }
    .filter-options a {
        font-size: 12px;
        letter-spacing: 0;
    }
    .filter-options a img {
        max-width: 10px;
    }
    .search-result-box .text-box {
        padding: 20px;
    }
    .search-result-box .text-box p {
        font-size: 12px;
        max-width: 100%;
        letter-spacing: 0px;
        margin-bottom: 7px;
    }
    .search-result-box .text-box h3 {
        font-size: 18px;
        letter-spacing: 0;
    }
    header .navbar .navbar-brand img {
        height: 25px;
    }
    .collection-breadcrumb img{
        max-width: 200px;
        width: 100%;
    }
    .collection-breadcrumb .breadcrumb-item,
    .collection-breadcrumb .breadcrumb-item a{
        letter-spacing: 0;
    }
    .collection-breadcrumb {
        margin-bottom: 30px;
    }
    .collection-detail-slide .carousel-indicators {
        padding: 0;
        margin: 10px -5px 0;
    }
    .carousel-indicators li {
        padding: 0 5px;
    }
    .collection-detail-sec .text-plank {
        padding: 30px 0 0 0;
    }
    .collection-detail-sec .text-plank span {
        letter-spacing: 1px;
    }
    .collection-detail-sec .text-plank h3 {
        font-size: 28px;
        line-height: 37px;
        margin: 15px 0;
        padding-bottom: 15px;
    }
    .collection-detail-sec .text-plank p {
        line-height: 28px;
        font-size: 16px;
    }
    .collection-grades {
        margin: 30px 0 0 0;
        padding: 30px 0;
    }
    .heading h3 {
        font-size: 28px;
        margin-bottom: 10px;
    }
    .heading p {
        line-height: 28px;
        font-size: 16px;
    }
    .collection-grades-box .image-holder{
        background-color: #ffffff;
        border: 1px solid #cacaca;
        padding: 20px;
        width: 250px;
        margin: auto;
    }
    .collection-grades-box .image-holder img{
        display: block;
        margin: auto;
        width: 100%;
        max-width: 200px;
        min-height: 200px;
        max-height: 200px;
    }
    .collection-grades-box {
        margin: 15px 0;
    }
    .collection-grades-inner {
        padding: 15px 0 0 0;
    }
    .collection-grades-inner .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .collection-packaging {
        padding: 50px 0 0 0;
    }
    .collection-packaging-inner {
        padding: 30px 0 0 0;
    }
    .collection-packaging-left .text-box p {
        font-size: 14px;
    }
    .collection-packaging-left .text-box ul li {
        font-size: 14px;
    }
    .collection-packaging-left .text-box {
        padding-bottom: 30px;
    }
    .collection-packaging-right .text-box ul li, .collection-packaging-right .text-box p {
        font-size: 14px;
        line-height: 20px;
    }
    .collection-packaging-right .text-box {
        margin-bottom: 30px;
    }
    .collection-detail-sec {
        padding: 50px 0 20px;
    }
    .colonial-grades-sec {
        padding: 50px 0;
    }
    .colonial-grades-sec .text-box {
        padding: 20px;
        margin: -70px auto 0;
    }
    .colonial-grades-sec .text-box h3 {
        line-height: 34px;
        font-size: 28px;
        padding-bottom: 15px;
    }
    .colonial-grades-sec .text-box h3:after {
        width: 100%;
    }
    .colonial-grades-sec .text-box p {
        font-size: 16px;
        line-height: 28px;
        padding-top: 15px;
    }
    .colonial-grades-box-second {
        margin-top: 30px;
    }
    .slideshow-sec .text-box {
        padding-bottom: 30px;
        padding-top: 20px;
    }
    .slideshow-sec .text-box span {
        padding-left: 15px;
        padding-right: 15px;
    }
    .slideshow-sec .text-box p {
        line-height: 28px;
        font-size: 16px;
        margin: 30px auto 0;
    }
    .gallery-inner-sec {
        padding: 50px 0 0;
    }
    .gallery-inner-sec .collection-breadcrumb {
        margin-bottom: 30px;
    }
    .distribution-banner .text-box h2 {
        font-size: 36px;
        line-height: 60px;
        max-width: 240px;
        margin: 0 auto 15px;
    }
    .distribution-banner .text-box h2 span {
        top: 50px;
    }
    .distribution-banner .text-box form {
        padding-left: 0;
        padding-right: 0;
    }
    .distribution-banner {
        padding: 50px 0;
    }
    .distribution-page .search-result-sec {
        padding: 50px 0 20px;
    }
    .distribution-page .search-result-box .text-box {
        padding: 20px;
    }
    .distribution-page .search-result-box .text-box ul {
        margin: 20px 0;
    }
    .distribution-page .search-result-box .text-box ul li {
        font-size: 16px;
    }
    .template-page .distribution-banner {
        padding: 50px 0;
    }
    .template-page .distribution-banner .text-box h2 {
        font-size: 40px;
        line-height: 46px;
    }
    .tempate-qualtiy {
        padding: 50px 0;
    }
    .tempate-qualtiy .text-box h3 {
        line-height: 36px;
        padding-bottom: 15px;
        font-size: 26px;
        margin-bottom: 15px;
    }
    .tempate-qualtiy .text-box p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 10px;
    }
    .template-letter-sec .text-box {
        padding: 20px;
    }
    .template-letter-sec .text-box span {
        font-size: 12px;
        padding: 0 10px 10px;
        letter-spacing: 0;
    }
    .template-letter-sec .text-box p {
        font-size: 14px;
        line-height: 24px;
        max-width: 100%;
        margin: 12px auto 20px;
    }
    .template-letter-sec .text-box h4 {
        font-size: 16px;
        margin-bottom: 4px;
    }
    .template-letter-sec .text-box strong {
        font-size: 14px;
    }
    .template-millions-sec {
        padding: 50px 0;
    }
    .template-title h3 span{
        padding: 0;
    }
    .template-title h3 {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .template-title p {
        font-size: 16px;
        line-height: 28px;
    }
    .template-millions-inner ul li {
        padding: 25px 0;
    }
    .template-millions-inner.mobile-version {
        padding: 20px 0 0;
    }
    .contact-us-detail {
        padding: 50px 0;
    }
    .contact-us-detail-inner .form-group .form-control {
        padding-left: 0;
        height: 36px;
        font-size: 13px;
    }
    .contact-us-detail-inner .form-group {
        margin-bottom: 20px;
    }
    .contact-us-getting span {
        letter-spacing: 0;
        padding: 0 13px 15px;
    }
    .contact-us-getting h3 {
        font-size: 32px;
        line-height: 44px;
        padding: 20px 0;
    }
    .contact-us-page .search-result-box .text-box {
        padding: 20px;
    }
    .contact-banner .text-box h2 {
        font-size: 40px;
    }
    .contact-banner {
        padding: 50px 0;
    }
    .contact-banner .text-box {
        text-align: center;
    }
    .contact-us-page .search-result-sec {
        padding: 50px 0;
    }
    .contact-us-page .search-result-inner .title {
        padding-bottom: 20px;
        margin-bottom: 15px;
    }
    .contact-us-page .search-result-detail p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 10px;
    }
    .contact-us-page .search-result-detail .text-box ul li {
        font-size: 16px;
    }
    .contact-us-page .search-result-detail {
        margin-bottom: 30px;
    }

    /* LIGHTBOX */
    .ril__navButtonPrev {
        width: 100px !important;
    }

    .ril__navButtonNext {
        width: 100px !important;
    }
    .gallery-inner-sec{
        padding: 60px 0 120px;
        background-color: #fafafa;
    }
    .gallery-inner-box .image-holder{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 5px;
        margin-bottom: 30px;
        min-width: 160px;
        max-width: 160px;
        min-height: 160px;
        max-height: 160px;
        overflow: hidden;
    }
    .gallery-inner-box .left-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .right-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .image-holder img{
        object-fit: cover;
        width: 150px;
        height: 148px;
    }
    .gallery-inner-box .left-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
    .gallery-inner-box .small-left{
        float: right;
        margin-right: 5px;
        margin-bottom: 10px;
    }
    .gallery-inner-box .small-right{
        float: left;
        margin-left: 5px;
        margin-bottom: 10px;
    }
    .gallery-inner-box .right-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }

}
@media(max-width:320px)   {
    .hardwood-option-box .text-box h3 {
        font-size: 12px;
    }
    .hardwood-option-box .text-box a {
        font-size: 10px;
    }
    .gallery-inner-sec{
        padding: 60px 0 120px;
        background-color: #fafafa;
    }
    .gallery-inner-box .image-holder{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 5px;
        margin-bottom: 30px;
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
        overflow: hidden;
    }
    .gallery-inner-box .left-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .right-img{
        background-color: #fff;
        border: 1px solid #cacaca;
        padding: 20px;
        margin-bottom: 30px;
        min-width: 440px;
        max-width: 440px;
        min-height: 430px;
        max-height: 430px;
    }
    .gallery-inner-box .image-holder img{
        object-fit: cover;
        width: 88px;
        height:88px;
    }
    .gallery-inner-box .left-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
    .gallery-inner-box .right-img img{
        object-fit: cover;
        width: 600px;
        height: 388px;
    }
}



.sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}


.image-holder-home {
    height: 100%;
    width: 100%;
}

.image-holder-home .img-fluid {
    height: 100% !important;
}
